define("ember-render-helpers/helpers/will-destroy", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * This helper is activated immediately before the helper is un-rendered
   * (removed from the DOM). It does not run during or after initial render, or
   * when its arguments are updated.
   */
  class WillDestroyHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "fn", void 0);
      _defineProperty(this, "positional", void 0);
      _defineProperty(this, "named", void 0);
    }
    compute(positional, named) {
      const fn = positional[0];
      (false && !(typeof fn === 'function') && (0, _debug.assert)(`\`{{did-insert fn}}\` expects a function as the first parameter. You provided: ${fn}`, typeof fn === 'function'));
      this.fn = fn;
      this.positional = positional.slice(1);
      this.named = named;
    }
    willDestroy() {
      if (this.fn && this.positional && this.named) {
        const {
          fn
        } = this;
        fn(this.positional, this.named);
      }
      super.willDestroy();
    }
  }
  _exports.default = WillDestroyHelper;
});